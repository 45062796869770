<template>
  <StyledModal :visible="visible" :showCancel="false" @close="close" @submit="handleSubmit" width="600px">
    <div class="content">
      <div class="input_wrap">
        <el-input v-model="searchValue" placeholder="诊断名称" style="width: 250px; margin-right: 12px" @keyup.enter.native="handleSearch"></el-input>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
      </div>

      <div class="list" v-loading="loading">
        <template v-if="Array.isArray(showList) && showList.length > 0">
          <div class="list_item" v-for="(item, index) in showList" :key="item.icdCode" @click="handleSelect(item)">
            <div>{{ item.icdName }}</div>
            <el-icon v-if="item.selected" class="icon_check" :color="primaryColor">
              <Check />
            </el-icon>
          </div>
        </template>
        <el-empty v-else description="暂无数据" />
      </div>
    </div>
    <template #footer_extra></template>
  </StyledModal>
</template>

<script>
import { ref, computed } from 'vue'
import StyledModal from '@/components/modal/StyledModal/index.vue'
import request from '@/utils/request'
import { Check } from '@element-plus/icons-vue'
import { primaryColor } from '@/utils/consts'

export default {
  emits: ['submit'],
  data() {
    return { primaryColor }
  },
  props: {
    initialValue: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const visible = ref(false)

    const selectedList = ref([])

    const searchValue = ref('')
    const list = ref([])
    const loading = ref(false)
    const showList = computed(() => {
      return list.value.map((item) => {
        return {
          ...item,
          selected: selectedList.value.some((itm) => itm.icdCode == item.icdCode) ? true : false,
        }
      })
    })

    function open() {
      visible.value = true
      selectedList.value = props.initialValue
    }
    function close() {
      visible.value = false
      // 重置
      selectedList.value = []
      searchValue.value = ''
      list.value = []
    }
    function handleSearch() {
      if (!searchValue.value) return
      loading.value = true
      request({
        url: '/web/system/goods/getICDInfoList',
        data: { icdName: searchValue.value },
      })
        .finally(() => (loading.value = false))
        .then((data) => {
          list.value = data ?? []
        })
    }
    function handleSelect(item) {
      if (selectedList.value.some((itm) => itm.icdCode == item.icdCode)) {
        // 删除
        selectedList.value = selectedList.value.filter((itm) => itm.icdCode != item.icdCode)
      } else {
        // 添加
        selectedList.value = [...selectedList.value, { icdCode: item.icdCode, icdName: item.icdName }]
      }
    }
    function handleSubmit() {
      const value = Array.isArray(selectedList.value) && selectedList.value.length > 0 ? selectedList.value : ''
      ctx.emit('submit', value)
      close()
    }

    return {
      visible,
      open,
      close,
      searchValue,
      list,
      showList,
      loading,
      handleSearch,
      handleSelect,
      handleSubmit,
    }
  },
  components: { StyledModal, Check },
}
</script>
<style lang="less" scoped>
@import url('~@/common/styles.less');
.content {
  .input_wrap {
    display: flex;
    align-items: center;
  }

  .list {
    border: 1px solid rgb(213, 213, 213);
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 15px 0;
    .list_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgb(234, 234, 234);
      padding: 8px 10px;
      margin: 0 10px;
      cursor: pointer;

      .icon_check {
        margin-top: 3px;
      }
    }
  }
}
</style>
